@mixin modal($centerWidth, $windowWidth) {

    @include var(background-color, dark1);
    @include var(color, light1);

    z-index: 1001;
    box-shadow: $cm-box-shadow-dark-md;

    width: 100%;
    max-height: 98%;

    top: 50%;
    transform: translateY(-50%);

    position: fixed;
    overflow: auto;

    @media (min-width: $centerWidth) {
        @include var(border-radius, border-radius);
        position: relative;
        margin: 0 auto;
        max-width: $windowWidth;
        height: auto;
        width: auto;
    }
}
