/* The switch - the box around the slider */

.cm-switch-container {
    @include var(border-bottom-style, border-style);
    @include var(border-bottom-width, border-width);
    @include var(border-bottom-color, light2);

    display: block;
    position: relative;
    padding: 10px;
    padding-left: 66px;
    line-height: 20px;
    vertical-align: middle;
    min-height: 40px;

    &:last-child {
        border-bottom: 0;
    }

    &:first-child {
        margin-top: 0;
    }

    p {
        margin-top: 0;
    }
}

.cm-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
}

.cm-list-input:checked + .cm-list-label .slider {
    @include var(background-color, green1);
}

.cm-list-input.half-checked:checked + .cm-list-label .slider {
    @include var(background-color, green1);
    opacity: 0.6;

    &::before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }
}

.cm-list-input.only-required + .cm-list-label .slider {
    @include var(background-color, green2);
    opacity: 0.8;

    &::before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }
}

.cm-list-input.required:checked + .cm-list-label .slider {
    @include var(background-color, green2);
    opacity: 0.8;
    cursor: not-allowed;
}

.slider {
    box-shadow: $cm-box-shadow-dark-md;
}

.cm-list-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 30px;
}

.cm-list-title {
    font-size: 0.9em;
    font-weight: 600;
}

.cm-list-description {
    @include var(color, dark3);
    font-size: 0.9em;
    padding-top: 4px;
}

.cm-list-label {
    /* The slider */

    .cm-switch {
        position: absolute;
        left: 0;
    }

    .slider {

        @include var(background-color, white2);

        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        width: 50px;
        display: inline-block;
    }

    .slider::before {

        @include var(background-color, dark1);

        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 5px;
        bottom: 5px;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 30px;
    }

    .slider.round::before {
        border-radius: 50%;
    }

    input:focus + .slider {
        @include var(box-shadow-color, green3);
        box-shadow: 0 0 1px var(color, green3);
    }

    input:checked + .slider::before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
}

.cm-list-input:focus + .cm-list-label .slider {
    box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2),
        5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.cm-list-input:checked + .cm-list-label .slider::before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
